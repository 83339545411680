<template>
 <div class="bg-white rounded-lg shadow p-4">
  <!-- Table -->
  <div class="overflow-x-auto">
    <table class="w-full border border-gray-200">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column"
            class="px-4 py-2 bg-gray-100 text-gray-600 text-sm font-semibold uppercase tracking-wider border-b text-center"
          >
            {{ columnDisplayNames[column] || formatHeader(column) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in tableData"
          :key="row.id"
          :class="{ 'bg-gray-50': index % 2 === 0 }"
        >
          <td
            v-for="column in columns"
            :key="column"
            class="px-4 py-2 text-sm text-gray-700 border-b text-center"
          >
            {{ row[column] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination -->
  <div class="flex justify-center mt-4">
    <pagination-element
      :total-items="totalItems"
      :page-size="pageSize"
      :current-page="currentPage"
      @page-change="handlePageChange"
    />
  </div>
</div>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import PaginationElement from './PaginationElement.vue';

const props = defineProps({
  fetchString: {
    type: String,
    required: true
  },
  pageSize: {
    type: Number,
    default: 10
  }
});

const columns = ref([]);
const tableData = ref([]);
const totalItems = ref(0);
const currentPage = ref(1);

const API_URL = process.env.VUE_APP_API_URL;

// Define a mapping from actual column names to display names
const columnDisplayNames = {
  event: 'Event',
  route: 'Route', // You can add more mappings as needed
};

const formatHeader = (header) => {
  return header
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};

async function fetchData() {
  try {
    const url = `${API_URL}${props.fetchString}&page=${currentPage.value}&page_size=${props.pageSize}`;
    const response = await axios.get(url);
    tableData.value = response.data.results;
    columns.value = tableData.value.length > 0 ? Object.keys(tableData.value[0]) : [];
    totalItems.value = response.data.total_items;
  } catch (error) {
    console.error('Error fetching data:', error);
    tableData.value = [];
    totalItems.value = 0;
  }
}

// Handle page change and emit the event
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchData();
};

// Reset currentPage and fetch data when fetchString changes
watch(() => props.fetchString, () => {
  currentPage.value = 1; // Reset the current page to 1
  fetchData();
});

// Fetch data when component is mounted
onMounted(fetchData);
</script>

<style scoped>
/* Style table headers */
thead th {
  background-color: #f3f4f6; /* Light gray background for headers */
  font-weight: bold;
  text-transform: uppercase;
  text-align: center; /* Align headers to the center */
  border-bottom: 2px solid #e5e7eb;
  padding: 8px 12px;
}

/* Style table cells */
tbody td {
  text-align: center; /* Align cells to the center */
  border-bottom: 1px solid #e5e7eb;
  padding: 8px 12px;
}

/* Alternate row colors for better readability */
tbody tr:nth-child(even) {
  background-color: #f9fafb; /* Light gray for even rows */
}

/* Style pagination container */
.flex.justify-center {
  justify-content: center; /* Align pagination to the center */
}

/* Style pagination buttons */
.pagination-element button {
  background-color: #f87171; /* Light red */
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  font-size: 0.875rem; /* Small text */
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-element button:hover {
  background-color: #ef4444; /* Slightly darker red on hover */
}

.pagination-element button:disabled {
  background-color: #f3f4f6; /* Gray background for disabled */
  color: #9ca3af; /* Gray text */
  cursor: not-allowed;
}
</style>