// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from '../views/HomePage.vue';
import BIDashboard from '@/views/BIDashboard.vue';
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from "@/views/RegisterPage.vue";
import AccountActivation from "@/components/AccountActivation.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import MasterData from "@/views/MasterData.vue";
import { jwtDecode } from 'jwt-decode';
import AlertPageV2 from "@/views/AlertPageV2.vue";
import Instructions from '@/views/instruction-page.vue';
import Failure2 from '@/views/FailureTwo.vue';


// Define your routes array before using it to create the router instance
const routes = [
  {
    path: '',
    name: 'HomePage',
    component: BIDashboard,
    meta: { requiresAuth: true, title:"FenixFirstFix - Home" }
    
  },
  {
    path: '/dashboard',
    name: 'BIDashboard',
    component: BIDashboard,
    meta: { requiresAuth: true, title:"FenixFirstFix - Dashboard"  }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { public: true , title:"FenixFirstFix - Login"}  // Public route
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage,
    meta: {requiresAuth: true, requiresSuperAdmin: true, title:"FenixFirstFix - Register" }
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: AlertPageV2,
    meta: { requiresAuth: true, title:"FenixFirstFix - Alerts"  },
    
  },

  { 
    path: '/instructions',
    name: 'instructions',
    component: Instructions,
    meta: { requiresAuth: false, title:"FenixFirstFix - instructions"  },
  },

  { path: '/failure2', name: 'failure2', component: Failure2 },


  {
    path: '/activate/:token',
    name: 'AccountActivation',
    component: AccountActivation,
    meta: { public: true, title:"FenixFirstFix"  }  // Public route
  },
  { path: '/forgot-password', component: ForgotPassword,  meta: { public: true, title:"FenixFirstFix"  } },
  { path: '/reset-password', component: ResetPassword,  meta: { public: true, title:"FenixFirstFix"  } },
  { path: '/masterdata', component: MasterData, meta: { requiresAuth: true, title:"FenixFirstFix - Masterdata"  } },


];

// Now create the router using the routes array
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// src/router/index.js

router.beforeEach((to, from, next) => {
  // Set document title based on route metadata, or fall back to a default
  document.title = to.meta.title || 'FenixFirstFix'
  next()
})


// Navigation guard setup
router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem('token');

    if (to.matched.some(record => record.meta.requiresSuperAdmin)) {
        if (!token) {
            next({ name: 'LoginPage' });
        } else {
            try {
                const decoded = jwtDecode(token);
                console.log('Decoded Token:', decoded);
                if (decoded.is_superuser) {
                    next();
                } else {
                    console.warn('Access denied: User is not a superuser.');
                    next({ name: 'HomePage' }); // Redirect to dashboard or another safe page
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                next({ name: 'LoginPage' });
            }
        }
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token) {
            next();
        } else {
            next({ name: 'LoginPage' });
        }
    } else {
        next();
    }
});

export default router;