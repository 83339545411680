<template>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" rel="stylesheet">

  <div class="bg-gray-50 text-gray-800 font-sans h-screen w-full">
    <!-- Header -->
    <header class="bg-gradient-to-r from-fenix to-dark text-white py-8">
      <div class="container mx-auto text-center">
        <h1 class="text-4xl font-extrabold">Instruction Guide</h1>
        <p class="mt-2 text-lg">Your Comprehensive Troubleshooting Resource</p>
      </div>
    </header>

    <!-- Sidebar -->
    <div class="py-6 px-0 mb-0">
      <h2 class="text-2xl font-bold text">Navigation</h2>
      <ul class="space-y-4">
        <li v-for="link in navigationLinks" :key="link.name">
          <a :href="link.url" class="block py-2 px-4 rounded hover:bg-teal-600">
            {{ link.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="bg-white p-4">
      <div class="grid grid-cols-4 gap-4 text-center">
        <!-- Failure cards -->
        <div v-for="(failure, index) in filteredFailureData" :key="index" 
          :class="['block border text-white py-4 px-2 hover:bg-grey rounded', getFailureCardColor(failure.id)]"
          @click="toggleModal(index)">
          <div class="flex items-center justify-center space-x-2">
            <i :class="failure.icon"></i> 
            <span class="font-bold uppercase">{{ failure.event }}</span> <!-- Use event here --> 
          </div>
        </div>
      </div>
    </div>

    <main class="container mx-auto px-8 py-12">
  <!-- Modals -->
  <div v-for="(failure, index) in filteredFailureData" :key="index" v-show="isModalVisible[index]" 
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click="closeModal(index)">
    <div class="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 max-w-4xl" @click.stop>
      <!-- Stops the click event from propagating to the parent elements -->
      <div class="p-6 h-[80vh] overflow-y-auto">
      
        <h2 class="text-2xl font-semibold text-blue-700 mb-6">{{ failure.event }}</h2>
        <p class="text-left mb-5"><B>Cause of Failure:</B></p>
        <div v-for="(reason, reasonIndex) in failure.reasons" :key="reasonIndex" class="bg-gradient-to-r from-blue-50 to-blue-100 border border-blue-300 text-left rounded-lg shadow-md mb-6">
          <details class="px-6 py-4 text-lg font-semibold text-blue-900 cursor-pointer hover:bg-blue-200">
            <summary>{{ reason.Message }}</summary>
            <div class="p-6 bg-white border-t border-blue-300">
              <h3 class="text-xl font-bold text-gray-800 mb-4">Action Steps</h3>
              <details v-for="(check, checkIndex) in reason.control_boards" :key="checkIndex" 
                class="bg-gradient-to-r from-gray-50 to-gray-100 border border-gray-300 rounded-lg shadow-md mb-6">
                <summary class="px-4 py-3 text-lg font-semibold text-gray-800 cursor-pointer hover:bg-gray-200">{{ check.title }}</summary>
                <div class="p-4 text-gray-600 space-y-6">
                  <section v-if="check.tools.length">
                    <h4 class="text-lg font-bold text-purple-700 mb-2">Tools Required</h4>
                    <details v-for="(tool, toolIndex) in check.tools" :key="toolIndex" class="bg-gradient-to-r from-purple-50 to-purple-100 border border-purple-200 rounded-lg shadow-md mb-4">
                      <summary class="px-6 py-4 text-purple-900 cursor-pointer hover:bg-purple-200">{{ tool }}</summary>
                    </details>
                  </section>
                  <section v-if="check.spare_parts.length">
                    <h4 class="text-lg font-bold text-yellow-700 mb-2">Spare Parts Required</h4>
                    <details v-for="(part, partIndex) in check.spare_parts" :key="partIndex" class="bg-gradient-to-r from-yellow-50 to-yellow-100 border border-yellow-300 rounded-lg shadow-md mb-4">
                      <summary class="px-6 py-4 text-yellow-900 cursor-pointer hover:bg-yellow-200">{{ part.name }}</summary>
                      <div class="p-4 bg-white rounded-lg">
                        <p><strong>Description:</strong> {{ part.description }}</p>
                        <p><strong>Material No.:</strong> {{ part.material_number }}</p>
                        <p><strong>Instructions:</strong> {{ part.Instructions }}</p>

                      </div>
                    </details>
                  </section>
                </div>
              </details>
            </div>
          </details>
        </div>
      </div>
    </div>
  </div>
</main>

    <!-- Footer -->
    <footer class="bg-gray-100 py-6">
      <div class="container mx-auto text-center text-gray-600 text-sm">
        &copy; 2024 Fenix Rail Systems | All Rights Reserved
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "InstructionGuide",
  data() {
    return {
      failureData: [
        { id: 1, event: "Failure Event 1", icon: "fas fa-exclamation-circle", reasons: [], modalVisible: false },
        { id: 2, event: "Failure Event 2", icon: "fas fa-exclamation-triangle", reasons: [], modalVisible: false },
        { id: 3, event: "Failure Event 3", icon: "fas fa-hand-paper", reasons: [], modalVisible: false },
        { id: 4, event: "Failure Event 4", icon: "fas fa-bug", reasons: [], modalVisible: false },
        { id: 29, event: "Failure Event 29", icon: "fas fa-map-marker-alt", reasons: [], modalVisible: false },
        { id: 65, event: "Failure Event 65", icon: "fas fa-sync-alt", reasons: [], modalVisible: false },
        { id: 67, event: "Failure Event 67", icon: "fas fa-cogs", reasons: [], modalVisible: false },
        { id: 15, event: "Failure Event 15", icon: "fas fa-tools", reasons: [], modalVisible: false },
        { id: 18, event: "Failure Event 18", icon: "fas fa-toolbox", reasons: [], modalVisible: false },
        { id: 73, event: "Failure Event 73", icon: "fas fa-times-circle", reasons: [], modalVisible: false },
        { id: 77, event: "Failure Event 77", icon: "fas fa-broadcast-tower", reasons: [], modalVisible: false },
        { id: 72, event: "Failure Event 72", icon: "fas fa-exclamation", reasons: [], modalVisible: false },
        { id: 79, event: "Failure Event 79", icon: "fas fa-redo", reasons: [], modalVisible: false },
        { id: 82, event: "Failure Event 82", icon: "fas fa-times", reasons: [], modalVisible: false },
        { id: 91, event: "Failure Event 91", icon: "fas fa-route", reasons: [], modalVisible: false }
      ],
      searchQuery: "",
      isModalVisible: [] // Array to keep track of modals' visibility
    };
  },
  computed: {
    filteredFailureData() {
      // Filter failureData based on search query with a check for undefined event and empty reasons
      return this.failureData.filter(failure => 
        failure.event && 
        failure.event.trim() !== "" && // Ensure the event is not empty
        failure.reasons && failure.reasons.length > 0 // Ensure there are reasons for the failure
      );
    }
  },
  mounted() {
    this.fetchFailureData(); // Fetch failure data after component is mounted
  },
  methods: {
    toggleModal(index) {
      // Directly toggle modal visibility using array
      this.isModalVisible[index] = !this.isModalVisible[index];
    },

    closeModal(index) {
      // Close modal by setting its visibility to false
      this.isModalVisible[index] = false;
    },

    getFailureCardColor(id) {
      // Return different color classes based on failure event ID
      switch (id) {
        case 1:
          return 'bg-red-600'; // Red color for Failure Event 1
        case 2:
          return 'bg-red-600'; // Blue color for Failure Event 2
        case 3:
          return 'bg-red-600'; // Green color for Failure Event 3
        case 4:
          return 'bg-yellow-600'; // Green color for Failure Event 3
        case 29:
          return 'bg-red-600'; // Yellow color for Failure Event 29
        case 65:
          return 'bg-purple-600'; // Red color for Failure Event 1
        case 67:
          return 'bg-yellow-600'; // Blue color for Failure Event 2
        case 15:
          return 'bg-yellow-600'; // Green color for Failure Event 3
        case 91:
          return 'bg-yellow-600'; // Green color for Failure Event 3
        case 82:
          return 'bg-red-600'; // Yellow color for Failure Event 29
        case 79:
          return 'bg-red-600'; // Red color for Failure Event 1
        case 72:
          return 'bg-red-600'; // Blue color for Failure Event 2
        case 77:
          return 'bg-yellow-600'; // Green color for Failure Event 3
        case 73:
          return 'bg-red-600'; // Green color for Failure Event 3
        case 18:
          return 'bg-yellow-600'; // Yellow color for Failure Event 29
        default:
          return 'bg-gray-600'; // Default gray color for other events
      }
    },

    async fetchFailureData() {
      const failureIds = [1, 2, 3, 4, 29, 65, 67, 15, 18, 73, 77, 72, 79, 82, 91];
      
      // Get the referer and failureParam here
      const referer = document.referrer; // Get the referer URL
      console.log("Referrer loaded:", referer);
      
      const urlParams = new URLSearchParams(window.location.search);
      const failureParam = urlParams.get('ref');
      console.log("Query Parameter ref:", failureParam);

      // Fetch all failure data first
      for (const id of failureIds) {
        await this.fetchFailureDetails(id);
      }

      // Handle the query parameters or referer conditions here
      if (referer.includes('failure1')) {
        this.toggleModal(0);
      } else if (referer.includes('failure2')) {
        this.toggleModal(1);
      } else if (referer.includes('failure3')) {
        this.toggleModal(2);
      }
      else if (referer.includes('failure4')) {
        this.toggleModal(3);
      }
      else if (referer.includes('failure29')) {
        this.toggleModal(6);
      }
      else if (referer.includes('failure65')) {
        this.toggleModal(7);
      }
      else if (referer.includes('failure67')) {
        this.toggleModal(8);
      }
      else if (referer.includes('failure15')) {
        this.toggleModal(4);
      }
      else if (referer.includes('failure18')) {
        this.toggleModal(5);
      }
      else if (referer.includes('failure73')) {
        this.toggleModal(10);
      }
      else if (referer.includes('failure77')) {
        this.toggleModal(11);
      }
      else if (referer.includes('failure72')) {
        this.toggleModal(9);
      }
      else if (referer.includes('failure79')) {
        this.toggleModal(12);
      }
      else if (referer.includes('failure82')) {
        this.toggleModal(13);
      }
      else if (referer.includes('failure91')) {
        this.toggleModal(14);
      }



      if (failureParam === 'failure1') {
        this.toggleModal(0);
      } else if (failureParam === 'failure2') {
        this.toggleModal(1);
      } else if (failureParam === 'failure3') {
        this.toggleModal(2);
      } else if (failureParam === 'failure4') {
        this.toggleModal(3);
      } else if (failureParam === 'failure29') {
        this.toggleModal(6);
      } else if (failureParam === 'failure65') {
        this.toggleModal(7);
      } else if (failureParam === 'failure67') {
        this.toggleModal(8);
      } else if (failureParam === 'failure15') {
        this.toggleModal(4);
      } else if (failureParam === 'failure18') {
        this.toggleModal(5);
      } else if (failureParam === 'failure73') {
        this.toggleModal(10);
      } else if (failureParam === 'failure77') {
        this.toggleModal();
      } else if (failureParam === 'failure72') {
        this.toggleModal(9);
      } else if (failureParam === 'failure79') {
        this.toggleModal(12);
      } else if (failureParam === 'failure82') {
        this.toggleModal(13);
      } else if (failureParam === 'failure91') {
        this.toggleModal(14);
      }
    },

    async fetchFailureDetails(id) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}instruction/failure/${id}/`);
        const data = await response.json();
        const failureIndex = id - 1;
        this.failureData[failureIndex] = {
          id: id, // Ensure the id is part of the data
          event: data.event, // Store the actual event name
          icon: `fas fa-icon-${id}`, // Set appropriate icon
          reasons: data.failure_reasons, // Assign failure reasons
          modalVisible: false // Set modal visibility state
        };
      } catch (error) {
        console.error(`Error fetching failure data for ${id}:`, error);
      }
    }
  }
};
</script>

