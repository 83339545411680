<template>
  <div>
    <h1>Failure 2</h1>
    <!-- Button that triggers navigation -->
    <button @click="goToHomePage" class="btn btn-primary">
      Go to Home Page
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    // Navigate to the 'instructions' page with the 'failure2' ref query parameter
    goToHomePage() {
      this.$router.push({ name: 'instructions', query: { ref: 'failure2' } });
    }
  }
};
</script>

<style scoped>
/* Optional: Add some basic styles for the button */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}
</style>
